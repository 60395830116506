<template>
    <div class="row px-4">
      <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label> <span @click='cancel()' class="text-capitalize cursor-pointer text-gray-900 font-semibold">
        Category BISAC</span> <i class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i> <span
          class="text-gray-500 font-normal capitalize"> Add New</span>
      </label>
      <h4 class="text-xl capitalize">Edit Category BISAC</h4>
    </div>
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <h4 class="font-bold text-xl py-2.5">Edit Category BISAC</h4>
      <div class="w-1/2 flex-col">
        <div>
          <label for="name">Category BISAC Code</label>
          <input type="text" class="px-2.5 py-2.5 border rounded-lg w-full" id="name" placeholder="type category bisac code.."
          v-model="code" /> 
        </div>
        <div class="mt-2">
          <label class="block">Category BISAC Name</label>
          <input
                  type="text"
                class="px-2.5 py-2.5 border rounded-lg w-full"
                  id="name"
                  placeholder="type category bisac name.."
                  v-model="name"
                />
        </div>
        <div class="mt-3 mb-4">
          <t-checkbox type="checkbox" id="is_active" v-model="is_active" />
          <label class="form-check-label" for="is_active">Is Active</label>
        </div>

        <div class="flex justify-start pb-3">
          <button class="bg-blue-700 text-white rounded-lg px-3 py-2" @click="updateCategoryBisac">Save</button>
          <button class="bg-white text-gray-500 rounded-lg border px-3 py-2 ml-4" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { EventBus } from '../../event-bus'
export default {
  name: 'EditCategoryBisac',
  data() {
    return {
      code: '',
      name: '',
      is_active: true,
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.category_bisac.item,
      isError: (state) => state.category_bisac.isError,
      successMessage: (state) => state.category_bisac.successMessage,
      errorMessage: (state) => state.category_bisac.errorMessage,
    }),
  },
  watch: {
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.getCategoryBisac();
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
    successMessage: function() {
      if (!this.successMessage) return;
     // this.alertMsg(this.successMessage, 'success');
        EventBus.$emit('show-toast', {
        message: this.successMessage,
        type: 'success',
      });
      this.$router.push('/category-bisac');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      EventBus.$emit('show-toast', {
        message: this.errorMessage,
        type: 'error',
      });
    ///  this.alertMsg(this.errorMessage, 'error');
    },
  },
  created: function() {
    const categoryBisacID = this.$route.params.id;
    this.fetchCategoryBisacById({ id: categoryBisacID });
  },
  methods: {
    ...mapActions('category_bisac', [
      'fetchCategoryBisacById',
      'editCategoryBisac',
    ]),

    async getCategoryBisac() {
      const data = this.item;
      this.code = data.code;
      this.name = data.name;
      this.is_active = data.is_active;
    },

    async updateCategoryBisac() {
      const categoryBisacID = this.$route.params.id;
      let payload = {
        id: categoryBisacID,
        code: this.code,
        name: this.name,
        isActive: this.is_active,
      };

      this.editCategoryBisac(payload);
    },
    cancel() {
      this.$router.push('/category-bisac');
    },
  },
};
</script>
